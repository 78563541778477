export const industries = [
  {
    label: 'Offshore Vessels',
  },
  {
    label: 'Merchant Vessels',
  },
  {
    label: 'Other',
  },
];
export const companySizes = [
  {
    label: '0-10 employees',
  },
  {
    label: '11-50 employees',
  },
  {
    label: '51-200 employees',
  },
  {
    label: '201-500 employees',
  },
  {
    label: '501-1000 employees',
  },
  {
    label: '1000+ employees',
  },
];
export const companyTypes = [
  {
    label: 'Private company',
  },
  {
    label: 'Public company',
  },
  {
    label: 'Sole Proprietorship',
  },
  {
    label: 'Government or State-owned',
  },
  {
    label: 'Association / Other',
  },
];
export const cookies = {
  token: '@crewlink:tk',
  crewToken: '@crewlink:crewtk',
  loginType: '@crewlink:logintype',
};
export const contact_email = 'hello@onboardcrewapp.com';
export const nationalities = [
  'Indonesian',
  'Bruneian',
  'Burmese',
  'Cambodian',
  'Filipino',
  'Laotian',
  'Malaysian',
  'Singaporean',
  'Thai',
  'Vietnamese',
  'Others',
];
export const religions = ['Christian', 'Muslim', 'Hindu', 'Buddhist', 'Other'];
export const maritalStatuses = ['Single', 'Married', 'Widowed'];
export const genders = ['Male', 'Female', 'Others'];
export const PlayStoreLink =
  'https://play.google.com/store/apps/details?id=com.onboard.android';

export const UNAUTHORIZED_URL = '/dashboard/unauthorized';
