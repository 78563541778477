import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanyAccount<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getCompanyRoles = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/roles`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  /**
   * Create company account function
   * @param data { "full_name": string, "email": string, "phone_number": string}
   * @param params null
   * @returns customer account object
   */
  createCompanyAccount = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/accounts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  geCompanyAccount = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/accounts`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchCompanyAccount = (id: string, data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/accounts/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getMyAccount = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/personal`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchMyAccount = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/personal`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchMyAccountEmail = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/personal/email`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompany = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchCompany = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  postCompany = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyDashboard = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/dashboard/data`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  otpSubmitOtp = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/otp`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  otpResendOtp = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/otp/resend`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
